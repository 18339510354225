<template>
  <section>
    <navi-bar>
      {{$t('front.common.cscenter')}}
    </navi-bar>

    <cs-nav-mobile/>

    <div class="coupontab">
      <a :class="{'on' : type == 'sports'}" @click="type = 'sports'">스포츠게임</a>
      <a :class="{'on' : type == 'mini'}" @click="type = 'mini'">미니게임</a>
      <a>가상게임</a>
    </div>

    <template v-if="type == 'sports'">
      <ul class="couponcount couponcount2">
        <li class="on">축구</li>
        <li>농구</li>
        <li>야구</li>
        <li>배구</li>
        <li>테니스</li>
        <li>하키</li>
        <li>축구</li>
        <li>농구</li>
        <li>야구</li>
        <li>배구</li>
        <li>테니스</li>
        <li>하키</li>
      </ul>
      <p class="pointname">모든 프로축구는 해당 리그의 공식협회가 인정하는<br>경기 결과대로 반영됩니다.</p>
      <div class="ruleboard">
        <ul class="head">
          <li>베팅타입</li>
          <li>적용시간</li>
          <li>베팅룰</li>
        </ul>
        <ul class="con">
          <li class="bbl">승무패</li>
          <li class="yw">연장제외</li>
          <li>승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">승무패<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">승무패<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">승패 (무X)</li>
          <li>연장없음</li>
          <li>승리가 예상되는 팀을 선택하여 베팅하는 방식 (무승부 적중특례)</li>
        </ul>
        <ul class="con">
          <li class="bbl">승패 (무X)<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 베팅하는 방식 (무승부 적중특례)</li>
        </ul>
        <ul class="con">
          <li class="bbl">승패 (무X)<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 베팅하는 방식 (무승부 적중특례)</li>
        </ul>
        <ul class="con">
          <li class="bbl">무실점<br>완승</li>
          <li class="yw">연장제외</li>
          <li>무실점으로 승리가 예상되는 팀을 선택하여 베팅하는 방식 (상대팀 무득점이여야함)</li>
        </ul>
        <ul class="con">
          <li class="bbl">전반 &amp;<br>후반전</li>
          <li class="yw">연장제외</li>
          <li>전반전만의 결과와 최종(전후반포함) 각각의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">더블찬스</li>
          <li class="yw">연장제외</li>
          <li>두개의 결과를 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">더블찬스<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀이 득점한 경기결과로 두개의 결과를 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">더블찬스<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀이 득점한 경기결과로 두개의 결과를 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡</li>
          <li class="yw">연장제외</li>
          <li>상대적으로 전력이 우수한 팀에게 불리한 조건을 주어 경기의 균형을 맞추고 승리가 예상되는 팀을 선택하여 베팅하는 방식 홈팀 (왼쪽) 기준으로 +,-의 핸디캡 기준점수가 표기됨.      </li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡<br>(전반전)</li>
          <li>연장없음</li>
          <li>핸디캡을 적용하여 전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡<br>(후반전)</li>
          <li>연장없음</li>
          <li>핸디캡을 적용하여 후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡 승무패</li>
          <li>연장없음</li>
          <li>핸디캡을 적용하여 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡 승무패<br>(전반전)</li>
          <li>연장없음</li>
          <li>핸디캡을 적용하여 전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡 승무패<br>(후반전)</li>
          <li>연장없음</li>
          <li>핸디캡을 적용하여 후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="gn">언더오버</li>
          <li class="yw">연장제외</li>
          <li>양팀의 최종 득점의 합이 기준점 보다 높고 낮음을 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="gn">언더오버<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀이 득점한 합이 기준점 보다 높고 낮음을 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="gn">언더오버<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀이 득점한 합이 기준점 보다 높고 낮음을 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">홀짝</li>
          <li class="yw">연장제외</li>
          <li>양팀의 최종 득점의 합이 홀 또는, 짝으로 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">홀짝<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀이 득점의 합이 홀 또는, 짝으로 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">홀짝<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀이 득점의 합이 홀 또는, 짝으로 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="bbl">양팀득점</li>
          <li class="yw">연장제외</li>
          <li>경기결과 양팀모두 득점이있음 또는 없음을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">양팀득점<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 경기결과 양팀모두 득점이 있음 또는 없음을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">양팀득점<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 경기결과 양팀모두 득점이 있음 또는 없음을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계~</li>
          <li class="yw">연장제외</li>
          <li>경기결과 양팀에서 발생한 득점의 합(~이상)을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계~<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 경기결과로 양팀에서 방행한 득점의 합(~이상)을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계~<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 경기결과로 양팀에서 방행한 득점의 합(~이상)을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계</li>
          <li class="yw">연장제외</li>
          <li>경기결과 양팀에서 발생한 득점의 합을 정확히 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 경기결과로 양팀에서 방행한 득점의 합을 정확히 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="or">점수합계<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 경기결과로 양팀에서 방행한 득점의 합을 예측하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="gn">최고득점구간</li>
          <li class="yw">연장제외</li>
          <li>경기중 최고 많은 득점이 발생 할 구간(전,후반)을 예측하여 베팅하는 방식(두개이상의 동일한 최고득점 구간이 발생할 경우 적특처리)</li>
        </ul>
        <ul class="con">
          <li class="pp">스코어</li>
          <li class="yw">연장제외</li>
          <li>양팀의 경기 결과에 대한 최종 스코어를 정확하게 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">스코어<br>(전반전)</li>
          <li>연장없음</li>
          <li>전반전만의 양팀의 경기 결과에 대한 최종 스코어를 정확하게 예상하여 베팅하는 방식</li>
        </ul>
        <ul class="con">
          <li class="pp">스코어<br>(후반전)</li>
          <li>연장없음</li>
          <li>후반전만의 양팀의 경기 결과에 대한 최종 스코어를 정확하게 예상하여 베팅하는 방식</li>
        </ul>
      </div>
      <div class="ruleboard ruleboard2 mb100">
        <ul class="head">
          <li>타입및 경기시간</li>
          <li>결과적용 기준</li>
        </ul>
        <ul class="con">
          <li><span class="yw">연장제외</span><span>연장없음</span></li>
          <li>정규시간 (전반전/후반전)의 경기결과만 적용합니다.<br>연장전 및 승부차기는 제외됩니다.</li>
        </ul>
        <ul class="con">
          <li class="br">핸디캡(전체)</li>
          <li>선택한 기준점수는 미만 또는 초과 이므로, 연장을 포함하여 핸디캡 적용. 결과가 무승부일 경우는 미당첨 처리됨.</li>
        </ul>
        <ul class="con">
          <li class="gn">언더오버(전체)</li>
          <li>선택한 기준점수는 연장을 포함하여 동일한 기준점수로 종료 될 경우 적중특례 처리됨.</li>
        </ul>
      </div>
    </template>
    <template v-if="type == 'mini'">
      <ul class="couponcount">
        <li :class="{'on' : subType == 'power'}" @click="subType = 'power'">파워볼</li>
        <li :class="{'on' : subType == 'ladder'}" @click="subType = 'ladder'">파워사다리</li>
        <li :class="{'on' : subType == 'kino'}" @click="subType = 'kino'">스피드키노</li>
        <li>키노사다리</li>
      </ul>
      <template v-if="subType == 'power'">
        <div class="miniruls mb100"><img src="@/assets_mobile/img/minipower.png" /></div>
      </template>
      <template v-if="subType == 'ladder'">
        <div class="miniruls mb100"><img src="@/assets_mobile/img/miniladder.png" /></div>
      </template>
      <template v-if="subType == 'kino'">
        <div class="miniruls mb100"><img src="@/assets_mobile/img/minikino.png" /></div>
      </template>
    </template>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import CsNavMobile from '@/views_mobile/member/cs/nav'

export default {
  name: 'rule',
  components: {
    CsNavMobile,
    NaviBar
  },
  data () {
    return {
      type: 'sports',
      subType: 'power'
    }
  }
}
</script>
<style scoped>
.miniruls {width: calc(100% - 20px);margin: 0 10px;}
.miniruls img {width: 100%;}
.couponcount {margin: 20px 10px !important;font-size: 14px !important;font-weight: normal !important;gap: 10px !important;}
.couponcount li {padding-left: 10px !important;}
.couponcount li.on {color: #4c4c4c;}
</style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
